module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Emilia Qvist","short_name":"Emilia Qvist","description":"Emilia Qvist is a songwriter and singer, with music that combines modern pop with elements from soul, tied together by touching and honest lyrics","start_url":"/","background_color":"#fff","theme_color":"#c08c5f","display":"standalone","icon":"/home/runner/work/homepage/homepage/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f0a94fec3ae5e709d755d20639c67f2e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
