exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-listen-js": () => import("./../../../src/templates/listen.js" /* webpackChunkName: "component---src-templates-listen-js" */),
  "component---src-templates-music-js": () => import("./../../../src/templates/music.js" /* webpackChunkName: "component---src-templates-music-js" */),
  "component---src-templates-news-and-reviews-js": () => import("./../../../src/templates/news-and-reviews.js" /* webpackChunkName: "component---src-templates-news-and-reviews-js" */),
  "component---src-templates-press-kit-js": () => import("./../../../src/templates/press-kit.js" /* webpackChunkName: "component---src-templates-press-kit-js" */)
}

